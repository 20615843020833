<template>
  <section class="lista-pacienti w100">
    <header class="w100">
      <span class="breadcrumb">home > pacienti</span>

      <!-- FILTERS -->
      <div class="filters flex flex-between mtop10px">
        <div class="w100 mright10px">
          <drop-down :data="ddJudet" ref="clearJudet" @selected="setDDvalue" />
          <br />
          <drop-down :data="ddMedic" ref="clearMedic" @selected="setDDvalue" />
        </div>

        <div class="w100 mright10px">
          <drop-down :data="ddOras" ref="clearOras" @selected="setDDvalue" />
          <div class="flex align-center igienizare-checkbox">
            <input
              id="igienizare"
              type="checkbox"
              v-model="filters.igienizare"
            />
            <label for="igienizare" style="opacity: 0.8">igienizare</label>
          </div>
        </div>

        <drop-down :data="ddTara" @selected="setDDvalue" />

        <datepicker
          v-model="filters.deLa"
          :monday-first="true"
          :language="ro"
          placeholder="data inregistrarii"
          name="uniquename"
        ></datepicker>

        <datepicker
          class="pana-la-datepicker"
          v-model="filters.panaLa"
          :monday-first="true"
          :language="ro"
          placeholder="pana la"
          name="uniquename1"
        ></datepicker>

        <button class="cauta flex-center pointer" @click="filter">
          <i>
            <svg width="18px" height="18px" viewBox="0 0 18 18">
              <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g fill="#ffffff" fill-rule="nonzero">
                  <path
                    d="M7.125,0 C9.09375984,0 10.7734305,0.695305547 12.1640625,2.0859375 C13.5546945,3.47656945 14.25,5.15624016 14.25,7.125 C14.25,8.5 13.8750038,9.78124406 13.125,10.96875 L12.9375,11.296875 L18,16.40625 L16.40625,18 L11.34375,12.890625 L11.015625,13.125 C9.76561875,13.9062539 8.46875672,14.296875 7.125,14.296875 C5.15624016,14.296875 3.47656945,13.593757 2.0859375,12.1875 C0.695305547,10.781243 0,9.09375984 0,7.125 C0,5.15624016 0.695305547,3.47656945 2.0859375,2.0859375 C3.47656945,0.695305547 5.15624016,0 7.125,0 Z M7.125,1.5 C5.56249219,1.5 4.23438047,2.04686953 3.140625,3.140625 C2.04686953,4.23438047 1.5,5.56249219 1.5,7.125 C1.5,8.68750781 2.04686953,10.0156195 3.140625,11.109375 C4.23438047,12.2031305 5.56249219,12.75 7.125,12.75 C8.68750781,12.75 10.0156195,12.2031305 11.109375,11.109375 C12.2343806,9.98436938 12.796875,8.65625766 12.796875,7.125 C12.796875,5.59374234 12.2343806,4.26563062 11.109375,3.140625 C10.0156195,2.04686953 8.68750781,1.5 7.125,1.5 Z"
                    id="Combined-Shape"
                  ></path>
                </g>
              </g>
            </svg>
          </i>
        </button>

        <button class="clear-filters pointer relative" @click="clearFilter">
          <i v-html="svg.closeGreen"></i>
          <span v-if="isFilters" class="absolute"
            >Atentie: filtre aplicate!</span
          >
        </button>
      </div>

      <!-- SEARCH FIELD -->
      <div class="search-field-wrapper relative">
        <input
          class="block w100"
          cypress="search-pacient"
          v-model="searchString"
          @keyup="search"
          type="text"
          placeholder="caută pacient (nume, tel, email)"
        />
        <i class="absolute pointer">
          <svg width="18px" height="18px" viewBox="0 0 18 18">
            <g
              id="Page-1"
              stroke="none"
              stroke-width="1"
              fill="none"
              fill-rule="evenodd"
            >
              <g id="Combined-Shape-2" fill="#A7A1A1" fill-rule="nonzero">
                <path
                  d="M7.125,0 C9.09375984,0 10.7734305,0.695305547 12.1640625,2.0859375 C13.5546945,3.47656945 14.25,5.15624016 14.25,7.125 C14.25,8.5 13.8750038,9.78124406 13.125,10.96875 L12.9375,11.296875 L18,16.40625 L16.40625,18 L11.34375,12.890625 L11.015625,13.125 C9.76561875,13.9062539 8.46875672,14.296875 7.125,14.296875 C5.15624016,14.296875 3.47656945,13.593757 2.0859375,12.1875 C0.695305547,10.781243 0,9.09375984 0,7.125 C0,5.15624016 0.695305547,3.47656945 2.0859375,2.0859375 C3.47656945,0.695305547 5.15624016,0 7.125,0 Z M7.125,1.5 C5.56249219,1.5 4.23438047,2.04686953 3.140625,3.140625 C2.04686953,4.23438047 1.5,5.56249219 1.5,7.125 C1.5,8.68750781 2.04686953,10.0156195 3.140625,11.109375 C4.23438047,12.2031305 5.56249219,12.75 7.125,12.75 C8.68750781,12.75 10.0156195,12.2031305 11.109375,11.109375 C12.2343806,9.98436938 12.796875,8.65625766 12.796875,7.125 C12.796875,5.59374234 12.2343806,4.26563062 11.109375,3.140625 C10.0156195,2.04686953 8.68750781,1.5 7.125,1.5 Z"
                  id="Combined-Shape"
                ></path>
              </g>
            </g>
          </svg>
        </i>
      </div>
    </header>

    <div class="patients-table">
      <div v-if="$store.state.isLoading" class="flex-center">
        <BaseLoadingSpinner />
      </div>
      <ul v-else cypress="pacienti-table">
        <li
          @click="routeToPatient"
          :class="{ 'blocked-pacient': item.blocked }"
          v-for="(item, index) in pacientiByPage"
          :key="index"
        >
          <router-link :to="'/pacienti/history/' + item._id">
            <span class="id">#{{ item.id }}</span>
            <span cypress="pacient-nume" class="nume capitalize">
              <span v-if="item.igienizare" class="igiena-expirata">IG</span>
              {{ item.nume }}&nbsp;{{ item.prenume }}
            </span>
            <span class="medic capitalize text-left">{{
              makeAcronimMedic(item.medic)
            }}</span>
            <span class="tel">{{ item.telefon }}</span>
            <span class="data-nastere">{{
              moment.utc(item.birthdate, "MM-DD-YYYY").format("Do MMM YYYY")
            }}</span>
          </router-link>
        </li>
      </ul>

      <!-- PAGINATION -->
      <div class="table-footer flex flex-between">
        <div class="pagination flex align-center">
          <button
            class="left"
            :class="{ pointer: page > 1, opa5: page === 1 }"
            :disabled="page === 1"
            @click="pagination('prev')"
          >
            <i v-html="svg.arrowLeft"></i>
          </button>

          <!-- PAGE NUMBER -->
          <span class="page-number">{{ page }} / {{ numberOfPages }}</span>

          <button
            class="right"
            :class="{
              pointer: numberOfPages !== page,
              opa5: numberOfPages === page,
            }"
            :disabled="numberOfPages === page"
            @click="pagination('next')"
          >
            <i v-html="svg.arrowRight"></i>
          </button>
        </div>

        <!-- TOTAL -->
        <div class="flex align-center">
          <span class="total"
            >{{ pacienti.length }} pacien{{
              pacienti.length === 1 ? "t" : "ți"
            }}</span
          >
          <router-link
            data-test="add-pacient"
            @click="hideRightSideMenu"
            to="pacienti/add"
            class="add-patient-btn m-left-auto"
          >
            <i v-html="svg.addPacient"></i>
          </router-link>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  import { mapState, mapGetters } from 'vuex';
  import { ro } from 'vuejs-datepicker/dist/locale';
  import DropDown from '../components/dropdown.vue';
  import BaseLoadingSpinner from '../components/BaseLoadingSpinner';
  import moment from 'moment';
  import 'moment/locale/ro';
  import { EventBus } from '@/main';
  moment.locale('ro');
  export default {
    name: 'lista-pacienti',
    components: {
      DropDown,
      BaseLoadingSpinner
    },
    data () {
      return {
        searchString: '',
        filters: {
          judet: undefined,
          oras: undefined,
          tara: 'Romania',
          deLa: undefined,
          panaLa: undefined,
          medic: undefined,
          igienizare: false
        },
        isFilters: false,
        ro,
        ddJudet: {
          name: 'judet',
          placeholder: 'județ',
          ddData: 'judete',
          default: '',
          clbk: (judet) => {
            this.$store.commit('FILTER_ORASE_BY_JUDET', judet);
            this.judet = judet;
            this.ddOras.disabled = false;
            this.ddOras.default = undefined;
            this.filters.oras = undefined;
            EventBus.$emit('reset-dd-model', { name: 'oras' });
          }
        },
        ddOras: {
          name: 'oras',
          placeholder: 'localitate',
          ddData: 'orase',
          disabled: true
        },
        ddTara: {
          name: 'tara',
          placeholder: 'țară',
          ddData: 'tari',
          default: 'Romania'
        },
        ddMedic: {
          name: 'medic',
          placeholder: 'medic curant',
          ddData: 'medici',
          default: ''
        },
        moment,
        pageNumber: 1
      };
    },
    created () {
      !this.pacienti.length && this.$store.dispatch('get_all_patients');
      this.$store.dispatch('get_medici');
    },
    computed: {
      ...mapState({
        pacienti: (state) => state.pacientiModule.pacienti,
        page: (state) => state.pacientiModule.page,
        svg: 'svg'
      }),
      pacientiByPage () {
        const start = this.page === 1 ? 0 : (this.page - 1) * 10;
        const end = this.page === 1 ? this.page * 10 : this.page * 10;
        return this.pacienti.slice(start, end);
      },
      numberOfPages () {
        const length = this.pacienti.length;
        return Math.ceil(length > 10 ? length / 10 : 1);
      }
    },
    methods: {
      igienaExpirata ({ interval_igienizare, ultima_igienizare }) {
        return new Date().getTime() - ultima_igienizare > interval_igienizare;
      },
      makeAcronimMedic (medic) {
        return medic
          ? `${medic.split(' ')[0]?.substr(0, 1)}${medic.split(' ')[1]?.substr(0, 1)}`
          : '';
      },
      search () {
        this.$store.commit(
          'SEARCH_IN_PACIENTI',
          this.searchString.toLowerCase()
        );
      },
      filter () {
        this.$store.commit('FILTER_PACIENTI', this.filters);
        this.isFilters = true;
      },
      clearFilter () {
        this.$store.commit('RESET_FILTERS');
        this.$refs.clearJudet.clear();
        this.$refs.clearOras.clear();
        this.$refs.clearMedic.clear();
        this.filters = {
          judet: undefined,
          oras: undefined,
          tara: 'Romania',
          deLa: undefined,
          panaLa: undefined,
          medic: undefined,
          igienizare: false
        };
        this.isFilters = false;
        this.searchString = '';
      },
      setDDvalue ({ name, value }) {
        this.filters[name] = value;
      },
      routeToPatient () {
        this.$store.commit('SET_KEY_VALUE', {
          key: 'pacient',
          value: {
            history: [],
            _attachments: {},
            varsta: ''
          }
        });
        this.$store.commit('SET_KEY_VALUE', {
          key: 'isEditPacient',
          value: true
        });
      },
      hideRightSideMenu () {
        this.$store.commit('SET_KEY_VALUE', {
          key: 'isEditPacient',
          value: false
        });
      },
      pagination (direction) {
        this.$store.commit('BROWSE_PACIENTI_PAGES', direction);
      }
    },
    destroyed () {
      this.$store.commit('RESET_FILTERS');
    }
  };
</script>

<style lang="less">
  @import "../assets/styles/var";
  @import "../assets/styles/mixin";
  .lista-pacienti::-webkit-scrollbar {
    display: none;
  }
  .lista-pacienti {
    padding-top: 24px;
    overflow-y: initial !important;

    header {
      input {
        text-align: center;
        border-radius: 8px;
      }
      input + i {
        right: 12px;
        top: 9px;
      }
      .drop-down,
      .vdp-datepicker {
        width: 100%;
        margin-right: 8px;
      }
    }

    .filters {
      button.clear-filters {
        height: 32px;
        background: transparent;
        border: none;
        span.absolute {
          right: 0;
          color: darkred;
          display: block;
          width: 160px;
          top: 53px;
        }
        svg path {
          fill: grey;
          transition: fill 0.2s ease-in-out;
        }
        &:hover {
          svg path {
            fill: darken(grey, 20%, relative);
          }
        }
      }
    }

    .search-field-wrapper {
      margin-top: 28px;
    }

    .cauta {
      .circle(33px);
      flex: 0 0 auto;
      margin-top: 1px;
      margin-left: 4px;
      border: none;
      background-color: @main-green;
      outline: none;
      transition: background-color 0.1s ease-in-out;
      i {
        transform: scale(0.85);
      }
      &:hover {
        background-color: darken(@main-green, 20%, relative);
      }
    }

    .pana-la-datepicker {
      .vdp-datepicker__calendar {
        right: 0;
      }
    }

    .id {
      flex: 0 0 auto;
      width: 70px;
    }
    .nume {
      width: 260px;
      margin-right: 24px;
    }
    .medic {
      width: 160px;
    }
    .tel {
      width: 120px;
      margin-right: 24px;
    }
    .data-nastere {
      width: 120px;
      text-align: right;
    }

    .add-patient-btn {
      transform: translateY(-1px);
      transition: background-color 0.2s ease-in-out;
      svg circle {
        transition: fill 0.1s ease-in-out;
      }
      &:hover {
        svg circle {
          fill: darken(@main-green, 20%, relative);
        }
      }
    }

    .total {
      margin-right: 36px;
      display: inline-block;
      font-size: 1.8rem;
      opacity: 0.5;
      transform: translateY(-2px);
    }

    .blocked-pacient a {
      color: red !important;
    }

    .igiena-expirata {
      margin-left: -26px;
      .circle(22px);
      display: inline-flex;
      justify-content: center;
      align-items: center;
      flex: 0 0 auto;
      background-color: black;
      color: white;
      font-weight: bold;
      font-size: 1.2rem;
      transform: translate(-2px, -2px);
    }
    .igienizare-checkbox {
      margin-top: 8px;
      cursor: pointer;
      input,
      label {
        cursor: pointer;
      }
      input {
        width: 28px;
        height: 28px;
        margin-right: 12px;
        background: white;
        -webkit-appearance: none;
        -moz-appearance: none;
      }
      input:checked {
        background-image: url("../assets/checked-green.svg");
        background-repeat: no-repeat;
        background-position: center;
      }
      label {
        font-size: 1.7rem;
      }
    }
  }
</style>
